<template>

    <div class="popup active">
		<template v-if="checkProject == '1'">
        <div class="notice_popup human prj_sp">
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
            </div>
            
			<img class="prj_supply" src="/images/prj_supply.png" alt="프로젝트지원"/>
			<div v-if="gubun == 'insert'" class="tit">프로젝트 지원</div>
			<!-- <div v-else-if="gubun == 'delete'" class="tit">프로젝트 지원 취소</div> -->
            <div class="prj_name">
                <span class="name" title="">{{projectNm}}</span>
            </div>

			<template v-if="gubun == 'insert'">
				<p class="field">지원분야</p>
				<SelectComp type="select" title="지원분야 선택" v-model="projectRecrFieldSeq" :list="projectRecrFieldList" codeKey="projectRecrFieldSeq" nameKey="workCont" @change="changeProjectRecrField" />

				<div class="member">위 내용으로 해당 프로젝트에 지원하시겠습니까?</div>
				<div class="select-btn">
					<div class="btn" @click="$emit('close', false)">취소</div>
					<div class="btn" @click="saveCheck()">네, 지원 하겠습니다!</div>
				</div>
			</template>
			<!-- <template v-if="gubun == 'delete'">
				<div class="member">해당 프로젝트 지원을 취소하시겠습니까?</div>
				<div class="select-btn">
					<div class="btn" @click="$emit('close', false)">취소</div>
					<div class="btn" @click="save()">네, 취소 하겠습니다!</div>
				</div>
			</template>			 -->
            
        </div>
		</template>

		<template v-else>
        <div class="notice_popup human prj_sp check">
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
            </div>
            
			<img class="prj_supply" src="/images/prj_supply.png" alt="프로젝트지원"/>
			<div v-if="gubun == 'insert'" class="tit">프로젝트 지원</div>

			<template v-if="gubun == 'insert'">
				<div class="prj_outline">
					<div class="prj_name check">{{projectNm}}</div>
					<div class="outline">
						<div class="label ivl03">개요</div><div class="value">{{projectDtlDesc}}</div>
					</div>
					<div class="outline">
						<div class="label ivl04">고객사</div>
						<div style="display: inline-flex; width: 440px;">
							<div class="prj_outline_clientname">
								{{clientNm}}
							</div>
							<div class="prj_outline_clientloc">
								<img src="/images/location.png" alt="위치" />{{projectLocCont}}
							</div>
						</div>
					</div>
					<div class="outline">
						<div class="label ivl04">수행사</div><div class="value">{{corpNm}}</div>
					</div>
					<div class="outline">
						<div class="label ivl04">담당자</div><div class="value">{{corpMgrMberNm}}</div>
					</div>
					<div class="outline">
						<div class="label ivl02">참여기간</div><div class="value">{{projectRecrField.projectJoinStartEndYyyymmdd}}</div>
					</div>
					<div class="outline">
						<div class="label ivl02">지원분야</div><div class="value">{{projectRecrField.workCont}}</div>
					</div>
					<div class="outline">
						<div class="label ivl02">지원등급</div><div class="value">{{projectTechGradeCd}}</div>
					</div>
				</div>

				<div class="member"><span>{{mberInfo.mberNm}} ( {{mberInfo.gender | gender}} / {{mberInfo.birthYyyymmdd | birthYear}} )</span>님 위 정보로 지원하시겠습니까?</div>
				<div class="select-btn">
					<div class="btn" @click="$emit('close', false)">취소</div>
					<div class="btn" @click="save()">네, 지원 하겠습니다!</div>
				</div>
			</template>
        </div>
		</template>

    </div>

</template>

<script>

export default {

	

	props: ['param'],

	data() {
		return {
			projectSeq : this.param.projectSeq,			
			projectNm : this.param.projectNm,
			projectRecrFieldList : [],
			projectAppSeq : this.param.projectAppSeq,
			projectDtlDesc : this.param.projectDtlDesc,
			clientNm : this.param.clientNm,
			projectLocCont : this.param.projectLocCont,
			corpNm : this.param.corpNm,
			corpMgrMberNm : this.param.corpMgrMberNm,
			gubun : this.param.gubun,		// insert, delete		

			projectRecrFieldSeq: '',		// 선택된 지원분야 

			checkProject : '1',

			mberInfo : {},
			mberAsgInfo : {}, // 기술인재 소속사 승인 데이터 조회

		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');		
	},
	mounted() {
		// console.log('mounted');

		// 지원분야(모집분야) 종료된 분야 제외
		for(var i in this.param.projectRecrFieldList) {
			var recr = this.param.projectRecrFieldList[i];

			// 모집종료 여부 체크
			if(recr.recrEndYn != undefined && recr.recrEndYn == 'Y') {
				continue;
			}

			// 모집시작, 종료날짜 체크
			var now = new Date().format("yyyyMMdd");
			if(recr.recrTermStartYyyymmdd > now || recr.recrTermEndYyyymmdd < now) {
				continue;
			}
			this.projectRecrFieldList.push(recr);
		}

		if(this.projectRecrFieldList.length <= 0) {
			alert('모집중인 지원분야가 없습니다.');
			this.$emit('close', false);
		}
		//--
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {

		changeProjectRecrField(event, val, item){
            // console.log('changeProjectRecrField', event, val, item);
            this.projectRecrField = item || {};
        },

		save() {

			this.$.httpPost('/api/prj/tec/getMemberAsgInfo', {mberSeq : this.mberInfo.mberSeq})
				.then(res => {
					this.mberAsgInfo = res.data.mberAsgInfo;

					if((this.mberAsgInfo.asgApprStatus == '01')) this.$emit('close', "asgReqFalse");
					if((this.mberAsgInfo.asgApprStatus == '02')) this.$emit('close', "asgApprFalse");

				})
				.catch(err => {
					alert(err.response.data.error_description);
			});

			var input = { 
				projectRecrFieldSeq : this.projectRecrFieldSeq,
				projectAppSeq : this.projectAppSeq,
				projectDivCd : this.param.projectDivCd,
			}

			var apiUrl = '';
			if(this.gubun == 'insert') {
				apiUrl = '/api/prj/tec/insertProjectApp';
			} else if(this.gubun == 'delete') {
				apiUrl = '/api/prj/tec/deleteProjectApp';
			}

			this.$.httpPost(apiUrl, input)
				.then(res => {
					if(res.data) {
						this.$emit('close', true);	
					} else {
						this.$emit('close', "techGradeFalse");
					}
					
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});		
				
				

		},
		saveCheck() {
			// console.log(this.checkProject);
			// console.log(this.param);

			if(!this.projectRecrFieldSeq){
				alert("지원분야를 선택해주세요!");
				return false;
			}

			this.checkProject = '2';
			
			var param = {
				tecMberSeq : this.$store.state.userInfo.mberSeq,
			}
			this.$.httpPost('/api/prj/tec/getMemberPrjInfo', param)
				.then(res => {
					this.mberInfo = res.data;
					// this.mberInfo.tecMberSeq = res.data.mberSeq;
					// this.mberInfo.projectJoinCorpNm = res.data.projectJoinCorpNm == undefined ? '소속없음' : res.data.projectJoinCorpNm;
					// console.log(this.mberInfo);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});	
		}
	},

	computed : {
		projectTechGradeCd() {
			switch (this.projectRecrField.techGradeCd) {
				case '01' : return '초급';
				case '02' : return '중급';
				case '03' : return '고급';
				case '04' : return '특급';
				default:
					return "기술사";
			}
		}
	}
};
</script>
